import type { SVGProps } from 'react'

const IconAI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path d="M9.066 7.052C6.08 6.378 5.617 5.915 4.943 2.93a.3.3 0 0 0-.586 0C3.683 5.915 3.22 6.378.234 7.052a.3.3 0 0 0 0 .586c2.986.675 3.449 1.137 4.123 4.123a.3.3 0 0 0 .586 0c.675-2.986 1.137-3.448 4.123-4.123a.3.3 0 0 0 0-.586ZM11.767 2.402c-1.588-.358-1.81-.581-2.17-2.168a.3.3 0 0 0-.585 0c-.358 1.587-.581 1.81-2.168 2.168a.3.3 0 0 0 0 .586c1.587.358 1.81.581 2.168 2.169a.3.3 0 0 0 .586 0c.358-1.588.581-1.81 2.169-2.17a.3.3 0 0 0 0-.585Z" />
  </svg>
)
export default IconAI
